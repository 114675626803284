import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Helmet from 'react-helmet';
import Flexbox from 'flexbox-react';
import styled, { withTheme } from 'styled-components';
import { compose, setPropTypes, withState } from 'recompose';
import Auth from '../../containers/Auth';
import ActionLink from '../../components/ActionLink';
import SignupForm from '../../components/SignupForm';
import GoogleIcon from '../../components/icons/GoogleIcon';
import FacebookIcon from '../../components/icons/FacebookIcon';
import LinkedInIcon from '../../components/icons/LinkedInIcon';

import GoogleButton from '../../components/buttons/GoogleButton';
import FacebookButton from '../../components/buttons/FacebookButton';
import LinkedInButton from '../../components/buttons/LinkedInButton';

import { withSagaMiddleware } from '../../providers/SagaMiddlewareProvider';
import signUp from '../../sagas/user/account/signUp';

import {
  initiateGoogleAuth,
  initiateFacebookAuth,
  initiateLinkedInAuth,
  signUpError,
} from '../../actions/user';

import { selectError } from '../../selectors/user';

const SubTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 2.125em;
  text-align: center;
  font-weight: 300;
`;

const GoogleButtonActionLink = GoogleButton(ActionLink);
const FacebookButtonActionLink = FacebookButton(ActionLink);
const LinkedInButtonActionLink = LinkedInButton(ActionLink);

const FlexWrapper = styled(Flexbox)`
  background-color: #f5f5ff;
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: center;
  background-size: 18%;
  box-shadow: 0 9px 17px 0 rgba(21, 11, 57, 0.15);
  padding: 2.125em 3.75em;
  text-align: center;
`;

const LineThrough = styled.p`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #cccccc;
  line-height: 0.1em;
  margin: 10px 0 40px;

  span {
    color: #cccccc;
    font-weight: 300;
    font-size: 0.75em;
    background: #f5f5ff;
    padding: 0 20px;
  }
`;

const Title = styled.h1`
  text-align: center;
  color: white;
`;

export const SignupPage = ({
  theme,
  sagaMiddleware,
  location,
  error,
  areTermsChecked,
  setAreTermsChecked,
  isTermsErrorMessageVisible,
  setIsTermsErrorMessageVisible,
}) => {
  const handleSubmit = data => {
    const {
      fullName,
      email,
      password,
      terms,
      app,
      chatbot,
      cmsWeb,
      price,
      priceslider,
    } = data.toJS();
    const postData = {
      fullName,
      email,
      app,
      chatbot,
      cmsWeb,
      price,
      priceslider,
    };

    if (!terms) {
      setIsTermsErrorMessageVisible(true);
    } else {
      sagaMiddleware.run(signUp, { fullName, email, password });

      fetch(`https://hooks.zapier.com/hooks/catch/4010674/pj7a76/`, {
        method: 'post',
        body: JSON.stringify(postData),
      })
        .then(res => {
          console.log(res);
          console.log(res.data);
        })
        .catch(err => {
          console.log('AXIOS ERROR: ', err);
        });
    }
  };

  /**
   * When a user checks terms and conditions,
   * we want to keep that value here, because the form is only
   * for an email sign up, and a social sign up has nothing to do with it
   * except that user needs to accept the terms in the social sign up, too.
   */
  const onTermsChange = value => {
    setAreTermsChecked(value);
  };

  /**
   * Before we trigger a social sign up we need to validate
   * if the terms checkbox is on true.
   * And if not we pass a prop that will tell form to render an error message.
   */
  const validateTermsBeforeSocialSignUp = () => {
    setIsTermsErrorMessageVisible(!areTermsChecked);
    return areTermsChecked;
  };

  return (
    <Auth location={location}>
      <Helmet title="Create Account • Sava Events" />
      <Title>Welcome! Create your account.</Title>
      <FlexWrapper flexDirection="column">
        <SubTitle>Let’s finalize your account</SubTitle>
        <Flexbox flexDirection="column">
          <Flexbox
            flexGrow={2}
            flexShrink={1}
            flexDirection="column"
            alignItems="center"
          >
            {error && (
              <div
                style={{
                  color: theme.colors.attention,
                  marginBottom: '0.5em',
                  width: '70%',
                }}
              >
                {error.message}
              </div>
            )}
            <SignupForm
              onSubmit={handleSubmit}
              initialValues={getQueryStringValue()}
              onTermsChange={onTermsChange}
              isTermsErrorMessageVisible={
                !areTermsChecked && isTermsErrorMessageVisible
              }
            />
          </Flexbox>
          <Flexbox
            flexDirection="column"
            flexGrow={1}
            flexShrink={2}
            alignItems="flex-start"
          >
            <LineThrough>
              <span>OR</span>
            </LineThrough>

            <GoogleButtonActionLink
              action={initiateGoogleAuth}
              param={signUpError}
              validate={validateTermsBeforeSocialSignUp}
            >
              <div style={{ display: 'inline-block', verticalAlign: '-40%' }}>
                <GoogleIcon width={20} height={20} padding={0} color="white" />
              </div>{' '}
              Create Account using Google
            </GoogleButtonActionLink>
            <FacebookButtonActionLink
              action={initiateFacebookAuth}
              param={signUpError}
              validate={validateTermsBeforeSocialSignUp}
            >
              <div style={{ display: 'inline-block', verticalAlign: '-40%' }}>
                <FacebookIcon
                  width={20}
                  height={20}
                  padding={0}
                  color="white"
                  style={{ marginLeft: -4 }}
                />
              </div>{' '}
              Create Account using Facebook
            </FacebookButtonActionLink>

            <LinkedInButtonActionLink
              action={initiateLinkedInAuth}
              param={signUpError}
              validate={validateTermsBeforeSocialSignUp}
            >
              <div style={{ display: 'inline-block', verticalAlign: '-40%' }}>
                <LinkedInIcon
                  width={20}
                  height={20}
                  padding={0}
                  color="white"
                  style={{ marginLeft: -4 }}
                />
              </div>{' '}
              Create Account using LinkedIn
            </LinkedInButtonActionLink>
          </Flexbox>
        </Flexbox>
      </FlexWrapper>
    </Auth>
  );
};

function getQueryStringValue() {
  const params = new URL(document.location).searchParams;
  let response = {};

  for (let pair of params.entries()) {
    let newPair = { [pair[0]]: pair[1] };
    response = { ...response, ...newPair };
  }
  return response;
}

export default compose(
  withState('areTermsChecked', 'setAreTermsChecked', false),
  withState(
    'isTermsErrorMessageVisible',
    'setIsTermsErrorMessageVisible',
    false
  ),
  connect(
    createSelector(
      selectError(),
      error => ({ error: error.signup })
    )
  ),
  setPropTypes({
    error: PropTypes.object,
    location: PropTypes.object,
  }),
  withSagaMiddleware,
  withTheme
)(SignupPage);

/**
 *
 * Spinner
 *
 */

import PropTypes from 'prop-types';
import styled, { css, injectGlobal } from 'styled-components';

/* eslint no-unused-expressions: 0 */
injectGlobal`
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);hahah
      transform: rotate(360deg);
    }
  }
`;

const Spinner = styled.div`
  &,
  &:after {
    border-radius: 50%;
    ${props => css`
      width: ${props.size}em;
      height: ${props.size}em;
    `};
  }
  & {
    align-self: flex-start;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    transform: translateX(-50%);
    ${props => css`
      left: calc(50% - ${props.size / 2}em);
      top: calc(50% - ${props.size / 2}em);
      border-top: ${props.barThickness}px solid ${props.barBgColor};
      border-right: ${props.barThickness}px solid ${props.barBgColor};
      border-bottom: ${props.barThickness}px solid ${props.barBgColor};
      border-left: ${props.barThickness}px solid ${props.barFgColor};
      -webkit-animation: load8 ${props.rpm / 60}s infinite linear;
      animation: load8 ${props.rpm / 60}s infinite linear;
    `};
  }
`;

Spinner.displayName = 'Spinner';

Spinner.propTypes = {
  size: PropTypes.number.isRequired,
  barThickness: PropTypes.number.isRequired,
  barBgColor: PropTypes.string.isRequired,
  barFgColor: PropTypes.string.isRequired,
  rpm: PropTypes.number,
};

Spinner.defaultProps = {
  size: 8,
  barThickness: 8,
  barBgColor: 'rgba(124, 87, 226, 0.2)',
  barFgColor: 'rgba(124, 87, 226, 1)',
  rpm: 60,
};

export default Spinner;

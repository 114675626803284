import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled, { css } from 'styled-components';

import randomName from '../../utils/randomName';
import Icon from '../Icon';
import interactive, { button, link } from '../../utils/ui/interactive';
import QuestionForm from '../QuestionForm';
import { submitQuestion } from '../../actions/embed';
import { withRouter } from 'react-router-dom';
import queryString from 'qs';
import withUserRequestExecutor, {
  errorMessages,
} from '../../hocs/withUserRequestExecutor';
import { compose } from 'recompose';
import { sessionArchiveQuestionSaga } from '../../sagas/embed';
import Button from '../buttons/Button';

const QuestionsView = {
  Topbar: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Header: styled.h2`
    font-size: 1rem;
  `,
  Title: styled.div`
    font-size: 1.5em;
    font-weight: bold;
    @media screen and (max-width: 420px) {
      display: none;
    }
  `,
  Saved: styled.div`
    padding: 1rem;
    user-select: none;
    color: #333;
    ${button({ backgroundColor: '#eee' })};
  `,
};

const QuestionItem = styled.div`
  margin-bottom: 1em;
  padding: 0.5em 1em;
  background-color: #f3f4f5;
  color: #333;
`;

QuestionItem.Topbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.25rem;
`;

QuestionItem.Timestamp = styled.div`
  opacity: 0.75;
  font-size: 0.8em;
`;

QuestionItem.Save = styled.div`
  font-size: 0.8em;
  padding: 1rem;
  margin: -1rem;
  user-select: none;

  transition: transform 400ms ease-in-out;

  ${interactive.hover`
    transform: scale(1.1);
  `} ${interactive.active`
    transform: scale(0.75);
  `}
  
  > i {
    transition: color 400ms ease-in-out;
  }

  ${props =>
    props.saved
      ? css`
          transform: scale(1.25);

          ${interactive.hover`
      transform: scale(1.35);
    `} ${interactive.active`
      transform: scale(0.95);
    `}
    
    > i {
            color: yellowgreen;
          }
        `
      : null} ${link()};
`;

QuestionItem.User = styled.p`
  opacity: 0.75;
`;

QuestionItem.Avatar = styled.img`
  height: 1.15em;
  border-radius: 100%;
  vertical-align: text-top;
`;

const ActionGroup = styled.div`
  display: flex;
  *:nth-child(odd) {
    margin-left: 0.3em;
  }

  > :first-child {
    margin-top: 0;
    padding-top: 0;
    color: ${props => props.theme.colors.gray};
  }
`;

const ArchiveAction = ({ archived, handler }) => {
  return (
    <Button
      type={'link'}
      icon={archived ? 'visibility_off' : 'visibility'}
      onClick={() => (archived ? handler(false) : handler(true))}
      label={archived ? 'Unhide' : 'Hide'}
    />
  );
};

const NoQuestions = QuestionItem;

const using = (...args) => f => f(...args);

const readSaved = () => {
  try {
    const result = JSON.parse(localStorage.getItem('savedQuestions') || '[]');
    return Array.isArray(result) ? result : [];
  } catch (e) {
    return [];
  }
};

const isSaved = id => readSaved().indexOf(id) >= 0;

const toggleSaved = id => {
  const saved = readSaved();
  const index = saved.indexOf(id);
  if (index >= 0) {
    saved.splice(index, 1);
  } else {
    saved.push(id);
  }
  localStorage.setItem('savedQuestions', JSON.stringify(saved));
};

class SessionQuestionsView extends Component {
  static propTypes = {
    moderator: PropTypes.bool,
    questions: PropTypes.object,
  };

  state = {
    savedOnly: false,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor({
    errorMessages,
  });

  submitQuestion = (data, dispatch, { form }) => {
    const { question } = data.toJS();
    const { conferenceHandle, session, location } = this.props;
    const { client, userId } = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    const sessionId = session.get('id');
    dispatch(
      submitQuestion(
        conferenceHandle,
        sessionId,
        userId,
        client,
        question,
        form
      )
    );
    this.setState({ finished: true });
  };

  triggerArchiveQuestion = questionId => archived => {
    const { conferenceHandle, session } = this.props;
    const sessionId = session.get('id');
    this.defaultUserRequestExecutor(sessionArchiveQuestionSaga, {
      conferenceHandle,
      sessionId,
      questionId,
      archived,
    });
  };

  render() {
    const { moderator, questions: allQuestions, showQuestionForm } = this.props;
    const { savedOnly, finished } = this.state;
    const questions = savedOnly
      ? allQuestions.filter(question => isSaved(question.get('id')))
      : allQuestions;

    const publicQuestions = moderator
      ? questions
      : questions.filter(q => !q.get('archived'));

    /* eslint-disable no-nested-ternary */
    return (
      <Fragment>
        {!moderator && showQuestionForm ? (
          finished ? (
            <p>Thank you, your question has been submitted!</p>
          ) : (
            <Fragment>
              <QuestionsView.Title>Ask a question</QuestionsView.Title>
              <QuestionForm onSubmit={this.submitQuestion} />
            </Fragment>
          )
        ) : null}
        <QuestionsView.Topbar>
          <QuestionsView.Header>Questions</QuestionsView.Header>
          {moderator && (
            <QuestionsView.Saved
              onClick={() => this.setState({ savedOnly: !savedOnly })}
            >
              Show {savedOnly ? 'All Questions' : 'Saved Only'}
            </QuestionsView.Saved>
          )}
        </QuestionsView.Topbar>
        {publicQuestions && publicQuestions.count() > 0 ? (
          publicQuestions.map(question =>
            using(isSaved(question.get('id')))(saved => (
              <QuestionItem key={question.get('id')}>
                <QuestionItem.Topbar>
                  <QuestionItem.Timestamp>
                    {moment(question.get('timestamp')).format(
                      'YYYY/MM/DD hh:mm'
                    )}
                  </QuestionItem.Timestamp>
                  {moderator && (
                    <ActionGroup>
                      <ArchiveAction
                        archived={question.get('archived')}
                        handler={this.triggerArchiveQuestion(
                          question.get('id')
                        )}
                      />
                      <QuestionItem.Save
                        saved={saved}
                        onClick={() => {
                          toggleSaved(question.get('id'));
                          this.forceUpdate();
                        }}
                      >
                        <Icon>{saved ? 'star' : 'star_border'}</Icon>
                      </QuestionItem.Save>
                    </ActionGroup>
                  )}
                </QuestionItem.Topbar>
                <QuestionItem.User>
                  <QuestionItem.Avatar
                    src={`https://avatar.tobi.sh/${question.get('id')}?size=32`}
                    role="presentation"
                  />{' '}
                  {randomName(question.get('id'))} asks:
                </QuestionItem.User>
                <p>{question.get('question')}</p>
              </QuestionItem>
            ))
          )
        ) : moderator ? (
          allQuestions.count() > 0 ? (
            <NoQuestions>No saved questions.</NoQuestions>
          ) : (
            <NoQuestions>No questions yet.</NoQuestions>
          )
        ) : (
          <QuestionItem>
            <QuestionItem.User>
              <QuestionItem.Avatar
                src="https://avatar.tobi.sh/moderator?size=32`"
                role="presentation"
              />{' '}
              Moderator’s note:
            </QuestionItem.User>
            <p>Hey, there!</p>
            <p>
              Looks like there are no community questions, yet. Once someone
              submits a question it will appear here.
            </p>
            <p>
              Want to break the ice? Terrific!{' '}
              <strong>Use the event chatbot or mobile app</strong> to submit
              your question!
            </p>
          </QuestionItem>
        )}
      </Fragment>
    );
    /* eslint-enable no-nested-ternary */
  }
}

export default compose(
  withRouter,
  withUserRequestExecutor
)(SessionQuestionsView);

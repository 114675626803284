/*
 *  SessionPage Container
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import { List, Map } from 'immutable';
import styled, { ThemeProvider } from 'styled-components';
import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { slugify } from 'sava-shared/lib/utils/core';
import { isFunction } from 'is-of-type';
import { selectId } from '../../selectors/user';
import { link } from '../../utils/ui/interactive';
import SpeakersList from '../../components/SpeakersList';
import SessionQuestionsView from '../../components/SessionQuestionsView';
import Spinner from '../../components/Spinner';
import { getAverage } from '../../utils/math/ratings';
import RatingView from '../../components/RatingView';
import RichTextView from '../../components/RichTextView';
import Tags from '../../components/Tags';
import PoweredBy from '../../components/PoweredBy';
import responsive from '../../utils/ui/responsive';
import { AnalyticsEvents } from 'sava-shared/lib/utils/analytics';
import firebase from 'firebase';
import queryString from 'qs';

const Container = styled.div`
  display: grid;
  grid-template-columns: 20rem auto;
  grid-column-gap: 3rem;
  margin: auto;
  color: ${props => props.theme.page.color};
  -webkit-font-smoothing: antialiased;
  padding: 2rem;

  ${responsive.md.andSmaller`
    display: flex;
    flex-flow: column;
  `};
`;

const SessionContent = styled.div`
  grid-column: 1;
  ${props =>
    props.hideOnSmallScreens
      ? `
    @media screen and (max-width: 420px) {
      display: none;
    }
  `
      : ''}
`;

const Questions = styled.div`
  grid-column: 2;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavLinkWrapper = styled.div`
  margin-bottom: ${props => props.theme.baseSpacing}px;
`;

export const NavLink = styled(Link)`
  ${props => link({ color: props.theme.colors.accent })};
  font-weight: 600;
`;

const SessionDetails = styled.div`
  font-weight: 600;
  display: flex;
  margin-bottom: 0.75rem;
`;
SessionDetails.StartTime = styled.div`
  display: flex;
`;
SessionDetails.Day = styled.div`
  display: flex;
  margin-left: 1rem;
`;
SessionDetails.Track = styled.div`
  display: flex;
  margin-left: 1rem;
`;

const SessionTitle = styled.h1`
  font-size: ${props => (props.isQuestions ? '1.25rem' : '2.25rem')};
  line-height: 1.33;
  margin-top: 0.75rem;
  margin-bottom: ${props => props.theme.baseSpacing}px;
`;

const StyledSpeakersList = styled(SpeakersList)`
  margin-bottom: 1.5rem;
`;

const Separator = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 1.5rem 0;
`;

SessionDetails.StartTime = styled.div`
  display: flex;
`;
SessionDetails.Day = styled.div`
  display: flex;
  margin-left: 1rem;
`;
SessionDetails.Track = styled.div`
  display: flex;
  margin-left: 1rem;
`;

export const SessionPage = ({
  conferenceHandle,
  session,
  showDescription = false,
  showTags = false,
  moderator = false,
  showBackButton = true,
  showQuestionForm = true,
  showQuestions = true,
  linkProvider,
  speakerLinkProvider,
}) => {
  const provideLink = (route, { conferenceHandle, speakerName = '' } = {}) => {
    if (isFunction(speakerLinkProvider)) {
      return speakerLinkProvider(route, { conferenceHandle, speakerName });
    }
    switch (route) {
      case 'speaker':
        return `/@${conferenceHandle}/embed/speakers/${slugify(speakerName)}`;
      default:
        return undefined;
    }
  };

  if (session) {
    const speakers = session.get('speakers', List());
    const questions = session.get('questions', List());
    const ratings = session.get('ratings', List()).toJS();
    const averageRating = ratings.length > 0 ? getAverage(ratings) : 0;
    const tags = session.get('tags', List());

    return (
      <Container>
        <SessionContent hideOnSmallScreens={showQuestions}>
          <Helmet title={session.get('title')} />

          {showBackButton ? (
            <NavLinkWrapper>
              <NavLink to={linkProvider('back', { conferenceHandle })}>
                ← View Schedule
              </NavLink>
            </NavLinkWrapper>
          ) : null}

          {!showQuestions && (
            <SessionDetails>
              <SessionDetails.StartTime>
                {session.get('startTime').format('hh:mm')} –{' '}
                {session.get('endTime').format('hh:mm')}
              </SessionDetails.StartTime>
              <SessionDetails.Day>
                {session.get('startTime').format('dddd, MMMM Do')}
              </SessionDetails.Day>
              <SessionDetails.Track>
                {session.getIn(['track', 'name'])}
              </SessionDetails.Track>
            </SessionDetails>
          )}

          <SessionTitle isQuestions={showQuestions}>
            {session.get('title')}
          </SessionTitle>
          {ratings.length > 0 && (
            <RatingView
              averageRating={averageRating}
              totalRatings={ratings.length}
            />
          )}

          {speakers.count() > 0 && (
            <ThemeProvider theme={{ SpeakersList: { baseSize: 56 } }}>
              <StyledSpeakersList
                speakers={speakers}
                linkProvider={provideLink}
                usePageColor={true}
                {...{ conferenceHandle }}
              />
            </ThemeProvider>
          )}

          {showDescription && session.get('description') && (
            <RichTextView
              raw={
                session.get('description') &&
                JSON.parse(session.get('description'))
              }
            />
          )}

          {showTags && tags.count() > 0 && <Tags tags={session.get('tags')} />}

          {tags.count() > 0 && <Separator />}
        </SessionContent>

        {showQuestions && (
          <Questions>
            <SessionQuestionsView
              moderator={moderator}
              questions={questions}
              conferenceHandle={conferenceHandle}
              session={session}
              showQuestionForm={showQuestionForm}
            />
            <PoweredBy />
          </Questions>
        )}
      </Container>
    );
  }

  return (
    <Center>
      <Spinner />
    </Center>
  );
};

SessionPage.propTypes = {
  conferenceHandle: PropTypes.string.isRequired,
  session: PropTypes.any.isRequired,
  linkProvider: PropTypes.func,
};

const selectSessions = state => state.getIn(['embed', 'sessions'], Map());

const selectConferenceHandle = (state, props) =>
  (props.match.params || {}).conferenceHandle;

const mapStateToProps = createSelector(
  selectConferenceHandle,
  selectId,
  selectSessions,
  (conferenceHandle, sessionId, sessions) => ({
    conferenceHandle,
    session: sessions.find(
      session =>
        slugify(session.get('title')) === sessionId ||
        session.get('id') === sessionId
    ),
  })
);

export default compose(
  withRouter,
  connect(mapStateToProps),
  lifecycle({
    componentDidMount() {
      try {
        const { conferenceHandle, session, location = {} } = this.props;
        const { client, userId } = queryString.parse(location.search, {
          ignoreQueryPrefix: true,
        });
        firebase.functions().httpsCallable('logAnalyticsEvent')({
          eventCategory: AnalyticsEvents['QA/ENTER_QA'],
          client: client || 'web',
          userId: userId,
          timestamp: Date.now(),
          conferenceHandle,
          sessionId: session.get('id'),
        });
      } catch (e) {
        console.log(e);
      }
    },
  })
)(SessionPage);

/**
 *
 * OverlayModal
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isDirty } from 'redux-form/immutable';

import Icon from '../../components/Icon';

const CLOSE_CONFIRM_MESSAGE = 'You are about to leave this form. Are you sure?';

const Backdrop = styled.div`
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: rgba(124, 87, 226, 0.6);

  overflow-y: auto;

  z-index: 2000;
`;

const Window = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;

  padding: 1em 1.5em 1.5em;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 120px;
  position: relative;

  border-radius: 0.25em;
  outline: none;
  background: white;

  ${props => props.theme.altitude(1)};
`;

const CloseButton = styled(Flexbox)`
  padding: 6px

  margin-bottom: -20px;
  margin-right: -6px;

  position: absolute;
  top: .5em;
  right: 1.25em;
  cursor: pointer;

  z-index: 2;

  & > i {
    font-size: 24px;
  }
`;

class OverlayModal extends Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
  };

  static goBack() {
    this.props.history.goBack();
  }

  canCloseModal = () => {
    const { closeConfirmMessage, isDirty } = this.props;
    return isDirty
      ? window.confirm(closeConfirmMessage || CLOSE_CONFIRM_MESSAGE)
      : true;
  };

  static handleBackdropClick(e) {
    if (e.detail !== 'Window' && this.canCloseModal()) {
      this.goBack();
    }
  }

  static handleWindowClick(e) {
    /* eslint-disable no-param-reassign */
    e.detail = 'Window';
    /* eslint-enable no-param-reassign */
  }

  static handleKeyDown(e) {
    if (e.key === 'Escape' && this.canCloseModal()) {
      this.goBack();
    }
  }

  constructor(props) {
    super(props);

    this.goBack = OverlayModal.goBack.bind(this);

    this.handleBackdropClick = OverlayModal.handleBackdropClick.bind(this);
    this.handleWindowClick = OverlayModal.handleWindowClick.bind(this);
    this.handleKeyDown = OverlayModal.handleKeyDown.bind(this);
  }

  handleCloseButton = () => {
    if (this.canCloseModal()) {
      this.goBack();
    }
  };

  componentDidMount() {
    this.modal.focus();
  }

  render() {
    const { children, style } = this.props;

    return (
      <Backdrop justifyContent="center" onClick={this.handleBackdropClick}>
        <Window
          tabIndex="-1"
          innerRef={el => {
            this.modal = el;
          }}
          onClick={this.handleWindowClick}
          onKeyDown={this.handleKeyDown}
          style={style}
        >
          <CloseButton onClick={this.handleCloseButton} alignSelf="flex-end">
            <Icon>clear</Icon>
          </CloseButton>
          {children}
        </Window>
      </Backdrop>
    );
  }
}

const mapStateToProps = state => ({
  isDirty: !!state.get('form').find((_, formId) => isDirty(formId)(state)),
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(OverlayModal);

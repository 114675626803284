/*
 *  SessionPage Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import { List, Map } from 'immutable';
import styled, { ThemeProvider } from 'styled-components';
import { createSelector } from 'reselect';
import responsive from '../../../utils/ui/responsive';

import { selectId } from '../../../selectors/user';

import EmbedAwareNavLink from '../../../components/EmbedAwareNavLink';
import { getAverage } from '../../../utils/math/ratings';

import SpeakersList from '../../../components/SpeakersList';
import RichTextView from '../../../components/RichTextView';
import RatingView from '../../../components/RatingView';
import { slugify } from 'sava-shared/lib/utils/core';
import Spinner from '../../../components/Spinner';
import Tags from '../../../components/Tags';
import PoweredBy from '../../../components/PoweredBy';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 35rem;
  margin: auto;
  color: ${props => props.theme.page.color};
  -webkit-font-smoothing: antialiased;
  padding: 4em 2em;

  ${responsive.md.andSmaller`
    width: 100%;
  `};
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavLinkWrapper = styled.div`
  margin-bottom: ${props => props.theme.baseSpacing}px;
`;

const SessionDetails = styled.div`
  font-weight: 600;
  display: flex;
  margin-bottom: 0.75rem;
`;
SessionDetails.StartTime = styled.div`
  display: flex;
`;
SessionDetails.Day = styled.div`
  display: flex;
  margin-left: 1rem;
`;
SessionDetails.Track = styled.div`
  display: flex;
  margin-left: 1rem;
`;

export const SessionTitle = styled.h1`
  font-size: 2.25rem;
  line-height: 1.33;
  margin-top: 0.75rem;
  margin-bottom: ${props => props.theme.baseSpacing}px;
`;

const StyledSpeakersList = styled(SpeakersList)`
  margin-bottom: 1.5rem;
`;

class SessionPage extends Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    session: PropTypes.any.isRequired,
  };

  provideLink = (route, { conferenceHandle, speakerName } = {}) => {
    switch (route) {
      case 'speaker':
        return `/@${conferenceHandle}/embed/speakers/${slugify(speakerName)}`;
      default:
        return undefined;
    }
  };

  render() {
    const { conferenceHandle, session } = this.props;

    if (session) {
      const speakers = session.get('speakers', List());
      const ratings = session.get('ratings', List()).toJS();
      const averageRating = ratings.length > 0 ? getAverage(ratings) : 0;
      const tags = session.get('tags', List());

      return (
        <Container>
          <Helmet title="Session" />

          <NavLinkWrapper>
            <EmbedAwareNavLink to={`/@${conferenceHandle}/embed/schedule`}>
              ← View Schedule
            </EmbedAwareNavLink>
          </NavLinkWrapper>

          <SessionDetails>
            <SessionDetails.StartTime>
              {session.get('startTime').format('hh:mm')} –{' '}
              {session.get('endTime').format('hh:mm')}
            </SessionDetails.StartTime>
            <SessionDetails.Day>
              {session.get('startTime').format('dddd, MMMM Do')}
            </SessionDetails.Day>
            <SessionDetails.Track>
              {session.getIn(['track', 'name'])}
            </SessionDetails.Track>
          </SessionDetails>

          <SessionTitle>{session.get('title')}</SessionTitle>
          {ratings.length > 0 && (
            <RatingView
              averageRating={averageRating}
              totalRatings={ratings.length}
            />
          )}

          {speakers.count() > 0 && (
            <ThemeProvider theme={{ SpeakersList: { baseSize: 56 } }}>
              <StyledSpeakersList
                speakers={speakers}
                linkProvider={this.provideLink}
                usePageColor={true}
                {...{ conferenceHandle }}
              />
            </ThemeProvider>
          )}

          {session.get('description') && (
            <RichTextView
              raw={
                session.get('description') &&
                JSON.parse(session.get('description'))
              }
            />
          )}

          {tags.count() > 0 && <Tags tags={session.get('tags')} />}

          <PoweredBy conferenceHandle={conferenceHandle} />
        </Container>
      );
    }

    return (
      <Center>
        <Spinner />
      </Center>
    );
  }
}

const selectEmbed = state => state.getIn(['embed']);
const selectConferenceHandle = (state, props) =>
  (props.match.params || {}).conferenceHandle;

const mapStateToProps = createSelector(
  selectConferenceHandle,
  selectId,
  selectEmbed,
  (conferenceHandle, sessionId, embed) => {
    return {
      conferenceHandle,
      session: embed
        .getIn(['sessions'], Map())
        .find(
          session =>
            slugify(session.get('title')) === sessionId ||
            session.get('id') === sessionId
        ),
    };
  }
);

export default compose(
  withRouter,
  connect(mapStateToProps)
)(SessionPage);
